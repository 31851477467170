import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { useEffect } from "react";
import ogImage from "~/assets/img/og_image.png";
import { ExternalScripts } from "./components/ExternalScripts";
import { initialize } from "./services/analytics/analytics.client";
import stylesheet from "./styles/tailwind.css?url";
import transitionStyles from "./styles/transitions.css?url";

// import airbridge from "airbridge-web-sdk-loader";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  { rel: "stylesheet", href: transitionStyles },
];

export function loader({ request, context }: LoaderFunctionArgs) {
  return json({
    ENV: {
      // airbridgeAppName: context.env.AIRBRIDGE_APP_NAME as string,
      // airbridgeWebToken: context.env.AIRBRIDGE_WEB_TOKEN as string,
    },
  });
}

export function ErrorBoundary({ error }: { error: any }) {
  const routeError = useRouteError();
  captureRemixErrorBoundaryError(routeError);

  return (
    <div>
      <h1>Error</h1>
      <p>{error?.message}</p>
      <p>The stack trace is:</p>
      <pre>{error?.stack}</pre>
    </div>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();

  useEffect(() => {
    // airbridge.init({
    //   app: data.ENV.airbridgeAppName,
    //   webToken: data.ENV.airbridgeWebToken,
    // });

    initialize();
  }, []);

  return (
    <html
      lang="en"
      data-theme="light"
      className="min-h-[100vh]"
    >
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
        />
        <meta
          name="title"
          content="Zest"
        />
        <meta
          name="description"
          content="Flirt with your inner chef"
        />
        <Meta />
        {/* OpenGraph */}
        <meta
          property="og:title"
          content="Flirt with your inner chef"
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:url"
          content="https://zestapp.co"
        />
        <meta
          property="og:image"
          content={ogImage}
        />
        <meta
          property="og:description"
          content="Learn the hows and whys of making amazing food."
        />
        <Links />
      </head>
      <body className="min-h-[100vh]">
        <Outlet />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify((data as any).ENV)}`,
          }}
        />
        <Scripts />
        <ExternalScripts gtmTrackingId="GTM-K82Z8FT3" />
      </body>
    </html>
  );
}

export default withSentry(App);
